<template>
  <v-container>
    <l-map :zoom="zoom" :center="mapCenter" style="height: 50vh" :options="{worldCopyJump: true}" @click="addMarker" @contextmenu="rightClick">
      <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
      <l-marker v-for="(marker, idx) in markers" :lat-lng="marker" @click="removeMarker(idx)"/>
    </l-map>
  </v-container>
</template>

<script>
import {LIcon, LMap, LMarker, LTileLayer} from "vue2-leaflet";
import L from "leaflet";

export default {
  name: "VFRPlanner",
  components:{
    LMap,
    LIcon,
    LMarker,
    LTileLayer,
  },
  data: function(){
    return {
      zoom:5,
      url:'https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png',
      prefix: "",
      attribution:'&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
      mapCenter: L.latLng(0,0),
      markers: []
    }
  },
  methods: {
    addMarker(event){
      this.markers.push(event.latlng)
    },
    removeMarker(index){
      this.markers.splice(index, 1)
    },
    rightClick(event){
      // event.preventDefault()
      console.log("test")
      console.log(event)
    }
  }
}
</script>

<style scoped>

</style>
